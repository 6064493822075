<template>
  <div>
    <div v-if="showMobileMenu" class="site-cover" @click="menuClose"></div>
    <transition
      :name="transitionName"
      @before-enter="handleBeforeEnter"
      @after-enter="handleAfterEnter"
      @before-leave="handleBeforeLeave"
    >
      <nav
        v-if="showMobileMenu"
        :key="animateKeyCounter"
        class="mobile-site-navigation"
        aria-label="Main Menu"
        @keydown.esc="$store.dispatch('setShowMobileMenu', false)"
      >
        <FocusLoop :enabled="true">
          <MobileMenuActions
            v-if="showMobileMenu"
            v-show="showMenuActions"
            :show-active-section="activeMenus.length > 1"
            :show-back="activeMenus.length > 1"
            :title="currentMenuTitle"
            @back="menuBack"
            @close="menuClose"
          />
          <MobileMenu
            :menu="currentMenu"
            @link-clicked="linkClicked"
            @setActiveMenu="setActiveMenu"
          />
        </FocusLoop>
      </nav>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'
import isMobile from 'ismobilejs'
import stockMenus from './mobileMenus.json'
import mobileAccountMenus from './mobileAccountMenus.json'
import additionalMenus from './additionalMenus.json'
import { pushEvent } from '~/../common/utils/user'
import { mobileOccasionsMenu } from '~/utils/metadata'

export default {
  components: {
    FocusLoop: () => import('~/components/FocusLoop'),
    MobileMenu: () => import('~/components/Header/NavigationMenu/MobileMenu'),
    MobileMenuActions: () =>
      import('~/components/Header/NavigationMenu/MobileMenuActions'),
  },
  data() {
    return {
      selectedMenus: [],
      transitionDirection: 'left',
      animateKeyCounter: 0,
      showMenuActions: true,
      shownOnce: false,
    }
  },
  computed: {
    ...mapGetters([
      'constance',
      'loggedIn',
      'mobileMenuLevel',
      'showMobileMenu',
      'occasions',
      'gifting_menu_items',
    ]),
    isMobile() {
      return isMobile()
    },
    currentMenu() {
      return this.activeMenus[0]
    },
    currentMenuTitle() {
      if (this.currentMenu.text === 'root') {
        return 'Menu'
      }
      return this.currentMenu.text
    },
    transitionName() {
      if (
        this.currentMenu &&
        this.currentMenu.text === 'root' &&
        !this.shownOnce
      ) {
        return 'mobile-menu'
      }
      return `slide-${this.transitionDirection}`
    },
    activeMenus() {
      return [...this.selectedMenus, this.menus]
    },
    menus() {
      const menus = cloneDeep(stockMenus)
      menus.items.splice(0, 0, mobileAccountMenus)
      menus.items.splice(
        7,
        0,
        mobileOccasionsMenu(
          this.occasions,
          this.constance.EP1_ENABLE === 'True'
        )
      )
      menus.items.push(...additionalMenus.items)

      return menus
    },
    menuTransitionName() {
      return this.transitionDirection === 'left' ? 'slide-left' : 'slide-right'
    },
  },
  watch: {
    $route() {
      this.$nextTick(() => {
        this.$store.dispatch('setShowMobileMenu', false)
      })
    },
    showMobileMenu: {
      immediate: false,
      handler(newValue, oldValue) {
        if (newValue && !oldValue) {
          this.selectedMenus = []
          document.querySelector('body').classList.add('noscroll')
        } else {
          document.querySelector('body').classList.remove('noscroll')
        }
      },
    },
  },
  methods: {
    refreshAnimate(direction) {
      this.transitionDirection = direction
      this.animateKeyCounter += 1
    },
    setActiveMenu(menu) {
      this.selectedMenus = [menu, ...this.selectedMenus]
      this.refreshAnimate('left')
    },
    menuBack() {
      this.selectedMenus = this.selectedMenus.slice(1)
      this.refreshAnimate('right')
    },
    menuClose() {
      this.$store.dispatch('setShowMobileMenu', false)
    },
    linkClicked(item) {
      let parentName =
        this.currentMenu.text !== 'root' ? this.currentMenu.text : ''
      // The items under "Occasion" have categories
      if (item.category) parentName = parentName + ' / ' + item.category
      pushEvent(
        this.$axios,
        {
          metric_name: 'Global Nav Click',
          url_path: this.$route.fullPath,
          extra_data: {
            menu_name: item.text,
            parent_menu_name: parentName,
            target_url: item.url,
            is_mobile_web: true,
          },
        },
        this.$store
      )
      this.$router.push(item.url)
    },
    handleBeforeEnter() {
      this.showMenuActions = false
    },
    handleAfterEnter() {
      this.showMenuActions = true
    },
    handleBeforeLeave(el) {
      this.showMenuActions = false
      // The transition prevents v-show and v-if from taking effect right away
      // this manual style change will hide the menu actions immediately as the menu starts to close
      const menu = el.querySelector('.mobile-menu-header')
      if (menu) {
        menu.style.display = 'none'
      }
    },
  },
}
</script>
<style lang="scss">
.mobile-menu-enter-active,
.mobile-menu-leave-active {
  transition: left 0.5s ease;
}
.mobile-menu-enter,
.mobile-menu-leave-to {
  left: -100vw !important;
}
.mobile-menu-leave,
.mobile-menu-enter-to {
  left: 0 !important;
}

.slide-left-enter,
.slide-right-enter {
  position: absolute; // Position absolutely to not affect other elements
  width: 100%; // Maintain full width
  height: 100%;
  scale: 1;
}

.slide-left-enter-active,
.slide-left-leave-active {
  transition: left 0.5s ease, right 0.5s ease;
}

.slide-right-enter-active,
.slide-right-leave-active {
  transition: left 0.5s ease, right 0.5s ease;
}

.slide-left-enter {
  left: -100%;
  right: 100%;
}
.slide-right-enter {
  left: 100%; // Starts from completely right
  right: -100%; // Complement to left
}
</style>
